import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/index.vue')
  },
  {
    path: '/user/password',
    name: 'password',
    component: () => import('@/views/user/editPassword.vue')
  },
  {
    path: '/user/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue')
  },
  {
    path: '/user/register',
    name: 'register',
    component: () => import('@/views/user/register.vue')
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/home/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
