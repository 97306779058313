/**
 * 路由拦截器
 */
import router from './router'
import store from './store'
router.beforeEach((to, from, next) => {
    if (store.getters.userInfo.id) {
        next()
    } else {
        if (to.path === '/user/login' || to.path === '/user/register' || to.path === '/user/password') {
            next()
        } else {
            next('/user/login')
        }
    }
})
