<template>
  <router-view>
  </router-view>
  <div class="page-logo">
    <img src="@/assets/导航logo.png" alt="">
  </div>
</template>
<script setup>

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
</script>

<style lang="scss">
.page-logo {
  position: fixed;
  top: 30px;
  left: 50px;
  img {
    width: 120px
  }
}
</style>
