import request from '@/utils/useRequest'
export const http = {
    login: (data) => {
        return request({
            url: '/user/login',
            data,
            method: 'post'
        })
    },
    // 注册
    register: (data) => {
        return request({
            url: '/user/register',
            data,
            method: 'post'
        })
    },
    // 获取充值记录
    getRechargeRecord: (params) => {
        return request({
            url: '/recharge/page',
            params,
            method: 'get'
        })
    },
    getUserInfo: (id) => {
        return request({
            url: '/user/' + id,
            method: 'get'
        })
    },
    updateUserInfo: (id,data) => {
        return request({
            url: '/user/' + id,
            data,
            method: 'put'
        })
    },
    createRecharge: (data) => {
        return request({
            url: '/recharge',
            data,
            method: 'post'
        })
    },
    updatePassword: (data) => {
        return request({
            url: '/user/password',
            data,
            method: 'post'
        })
    }
}
